<template>
  <b-card>
    <div>
      <b-row>
        <b-col lg="10">
          <b-row>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-success"
              class="btn-icon rounded-circle ml-1"
              @click="showSearch = false"
              v-show="showSearch"
            >
              <feather-icon icon="SearchIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-warning"
              class="btn-icon rounded-circle ml-1"
              @click="showSearch = true"
              v-show="!showSearch"
            >
              <feather-icon icon="XIcon" />
            </b-button>

            <b-col v-show="!showSearch">
              <b-form-input
                placeholder="Pesquisar"
                v-model="search"
                debounce="800"
                size="sm"
                type="search"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col>
          <b-button variant="relief-success" size="sm" block @click="updateData(null)" v-b-toggle.sidebar-create>
            Adicionar
            <feather-icon icon="PlusIcon" />
          </b-button>
        </b-col>
      </b-row>
    </div>
    <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
    <b-table-simple
      striped
      stacked="md"
      class="mt-2 responsive text-center"
      id="tableElderlys"
      :item="fetchCompanies"
      :per-page="perPage"
      :current-page="currentPage"
    >
      <b-thead>
        <b-tr>
          <b-th>Logo</b-th>
          <b-th>Nome Fantasia</b-th>
          <b-th>WhatsApp</b-th>
          <b-th>Endereço</b-th>
          <b-th>Empresa exclusiva</b-th>
          <b-th>Ação</b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr :key="indextr" v-for="(tr, indextr) in companies">
          <b-td>
            <b-avatar
              size="40"
              variant="light-primary"
              :src="tr.logo"
            />
          </b-td>
          <b-td>{{tr.fantasy_name}}</b-td>
          <b-td>{{tr.whatsapp}}</b-td>
          <b-td>{{tr.address.street}}, Nº {{tr.address.number}} - {{tr.address.city}}/{{tr.address.state}}</b-td>
          <b-td>
            <b-badge
              v-if="tr.exclusive == 1"
              variant="success"
              class="badge-glow"
            >
              Exclusiva
            </b-badge>
            <b-badge
              v-else
              variant="danger"
              class="badge-glow"
            >
              Não exclusiva
            </b-badge>
          </b-td>
          <b-td>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              class="btn-icon rounded-circle"
              v-if="tr.exclusive == 1"
              :to="{ path: '/empresa/' + tr.id + '/exclusiva' }"
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              class="btn-icon rounded-circle"
              @click="updateData(tr)"
              v-b-toggle.sidebar-create
            >
              <feather-icon icon="Edit2Icon" />
            </b-button>

            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="flat-dark"
              class="btn-icon rounded-circle"
              @click="deleteData(tr.id)"
              v-b-modal.modal-delete
            >
              <feather-icon icon="Trash2Icon" />
            </b-button>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
    <b-pagination
      @change="handlePageChange"
      v-model="currentPage"
      :per-page="perPage"
      :total-rows="totalRows"
      aria-controls="tableElderlys"
      class="mt-1"
    />
    </b-overlay>
    <b-modal
      id="modal-delete"
      ok-variant="danger"
      modal-class="modal-danger"  
      ref="modal-delete"
      centered
      title="Deletar categoria"
    >
      <b-card-text  v-if="DeleteStatus != null">
        Por favor, confirme a exclusão antes de prosseguir. 
        <b-form-checkbox
          id="checkbox-1"
          @change="newDeleteStatus"
          v-model="DeleteStatus"
          name="checkbox-1"
          value="accepted"
        >
          Concordo em excluir esta empresas
        </b-form-checkbox>  
      </b-card-text>
      <template #modal-footer>
        <b-col>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            class="mt-2"
            variant="outline-danger"
            block
            :disabled="DeleteStatus == false"
            @click="deleteCompanies(deleteDatas.id)"
          >
            Deletar empresas
          </b-button>
        </b-col>
      </template>
    </b-modal>
    <create-edit :dataProp="sidebarData" @fetch-details="fetchCompanies" />
  </b-card>
</template>

<script>
import { BCard } from "bootstrap-vue";
import { mapActions, mapState } from "vuex";
import Ripple from "vue-ripple-directive";
import CreateEdit from "./CreateEdit";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BCard,
    CreateEdit
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      showSearch: true,
      isLoading: true,
      search: null,
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      sidebarData: {},
      deleteDatas: {},
      DeleteStatus: false

    };
  },
  computed: {
    ...mapState("companies", ["companies", "pagination"]),
  },
  methods: {
    fetchCompanies(pagination = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("companies/fetchCompanies", {
          pagination: pagination,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },

    updateData(val){
      this.sidebarData = {
        id: val.id,
        fantasy_name: val.fantasy_name,
        segment_id: val.segment_id,
        whatsapp: val.whatsapp,
        exclusive: val.exclusive,
        address: {
          zip_address: val.address.zip_address,
          street: val.address.street,
          number: val.address.number,
          complement: val.address.complement,
          neighborhood: val.address.neighborhood,
          city: val.address.city,
          state: val.address.state
        }
      }
    },

    deleteData(id){
      this.deleteDatas.id = id
    },

    newDeleteStatus(value){
      this.DeleteStatus = value
    },

    handlePageChange(value) {
      this.fetchCompanies(true, value);
    },

    deleteCompanies(id){
      this.isLoading = true;
      let data = id
      this.$store
        .dispatch("companies/delete", data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Excluído com sucesso!',
              icon: 'Trash2Icon',
              text: 'Operação executada com sucesso.',
              variant: 'danger'
            }
          })
          this.isLoading = false
          this.DeleteStatus = false
        })
        .catch(err => {
          console.error(err)
        })
        .finally(() => {
          this.sidebarData = {}
          this.fetchCompanies()
          this.$refs['modal-delete'].hide()
        })
    },

  },
  created() {
    this.fetchCompanies();
  },
  watch: {
     search(val) {
      this.currentPage = 1;
      this.fetchCompanies(true, 1);
    },

  },
};
</script>

<style>
</style>
