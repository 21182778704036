<template>
  <div>
    <b-sidebar
      id="sidebar-create"
      shadow
      right
      bg-variant="white"
      backdrop
      ref="sidebar-create"
      :title="dataProp.id == null ? 'Cadastrar Empresa' : 'Editar Empresa'"
    >
      <div>
        <validation-observer ref="simpleRules">
        <hr>
          <b-overlay :show="isLoadingStore" opacity="0.70" rounded="sm">
         <b-form enctype="multipart/form-data" method="POST">
          <b-form-group
            label="Nome fantasia:"
            label-for="i-nome"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="nome fantasia"
              rules="required"
            >
              <b-form-input
                id="i-nome"
                v-model="dataCompanies.fantasy_name"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group 
            label="Seguimento:" 
            label-for="i-segments"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="seguimento"
              rules="required"
            >
              <b-form-select
                id="i-segments"
                v-model="dataCompanies.segment_id"
                :options="segments"
                value-field="id"
                text-field="name"
                class="form-control"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Logo:"
            label-for="i-Icon"
            class="pl-1 pr-1"
          >
            <b-form-file
              placeholder="Logo"
              v-model="logo"
              type="file"
              accept="image/*"
              name="icon"
            />
          </b-form-group>
          <b-form-group
            label="WhatsApp:"
            label-for="i-name"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="WhatsApp"
              rules="required"
            >
              <b-form-input
                id="i-name"
                v-model="dataCompanies.whatsapp"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Empresa Exclusiva:"
            label-for="i-exclusiv"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="empresa exclusiva"
              rules="required"
            >
              <b-form-checkbox
                checked="0"
                class="custom-control-success"
                name="check-button"
                switch
                v-model="exclusive"
                :state="errors.length > 0 ? false:null"
              >
                <span class="switch-icon-left">
                  <feather-icon icon="CheckIcon" />
                </span>
                <span class="switch-icon-right">
                  <feather-icon icon="XIcon" />
                </span>
              </b-form-checkbox>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="CEP:"
            label-for="i-zip"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="ZIP"
              rules="required"
            >
              <b-form-input
                id="i-zip"
                v-model="dataCompanies.address.zip_address"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Rua:"
            label-for="i-street"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="rua"
              rules="required"
            >
              <b-form-input
                id="i-street"
                v-model="dataCompanies.address.street"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Numero:"
            label-for="i-number"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="numero"
              rules="required"
            >
              <b-form-input
                id="i-number"
                v-model="dataCompanies.address.number"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Complemento:"
            label-for="i-complement"
            class="pl-1 pr-1"
          >
            <b-form-input
              id="i-complement"
              v-model="dataCompanies.address.complement"
            />
          </b-form-group>
          <b-form-group
            label="Bairro:"
            label-for="i-neighborhood"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="bairro"
              rules="required"
            >
              <b-form-input
                id="i-neighborhood"
                v-model="dataCompanies.address.neighborhood"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Cidade:"
            label-for="i-city"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="cidade"
              rules="required"
            >
              <b-form-input
                id="i-city"
                v-model="dataCompanies.address.city"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
          <b-form-group
            label="Estado:"
            label-for="i-state"
            class="pl-1 pr-1"
          >
            <validation-provider
              #default="{ errors }"
              name="estado"
              rules="required"
            >
              <b-form-input
                id="i-state"
                v-model="dataCompanies.address.state"
                :state="errors.length > 0 ? false:null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>
        </b-form>
        <b-row style="margin-right: 0rem !important;">
          <b-col lg="5" sm="6" class="ml-1 mr-1">
            <b-button
              variant="relief-primary"
              @click="storeCompanies()"
              block
            >
              {{this.dataCompanies.id == null ? 'Salvar' : 'Editar'}}
            </b-button>
          </b-col>
          <b-col>
            <b-button 
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="outline-danger"
              @click="closeSidebar"
              block
            >
              Fechar
            </b-button>
          </b-col>
        </b-row>
        </b-overlay>
        </validation-observer>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
/* eslint-disable */
import { BButton, BSidebar, VBToggle} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapActions, mapState } from "vuex";
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  required, email, confirmed, password,
} from '@validations'
import { ValidationProvider, ValidationObserver } from 'vee-validate'

export default {
  components: {
    BButton,
    BSidebar,
    ValidationProvider,
    ValidationObserver
  },
  directives: {
    'b-toggle': VBToggle,
    Ripple,
  },
   props: { 
    dataProp: {
      type: Object,
      default: () => {}
    },
  },
  data() {
    return {
      dataCompanies: {
        id: null,
        fantasy_name: null,
        segment_id: null,
        whatsapp: null,
        address: {
          city: null,
          zip_address: null,
          street: null,
          state: null,
          number: null,
          complement: null,
          neighborhood: null,
        },
      },
      logo: undefined,
      exclusive: false,

      isLoadingStore: false,
      csrf: document.head.querySelector('meta[name="csrf-token"]') ? document.head.querySelector('meta[name="csrf-token"]').content : '',
    }
  },
  computed: {
    ...mapState("companies", ["companies"]),
    ...mapState("segments", ["segments", "pagination"]),
  },
  methods: {
    ...mapActions("companies", ["store", "update"]),
    storeCompanies(value) {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.isLoadingStore = true
          if (this.dataProp.id == null) { 
            this.store({
              ...this.dataCompanies,
              logo: this.logo,
              exclusive: this.exclusive == false ? 0 : 1
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues();
              this.closeSidebar();
              this.$emit("fetch-details", 
              {
                active: true,
              });
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }else{
            this.update({
              ...this.dataCompanies,
              logo: this.logo,
              exclusive: this.exclusive == false ? 0 : 1
            })
            .then(() => {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: 'Cadastrado com sucesso!',
                  icon: 'ThumbsUpIcon',
                  text: 'Operação executada com sucesso.',
                  variant: 'success'
                }
              })
              this.initValues();
              this.closeSidebar();
              this.$emit("fetch-details", 
              {
                active: true,
              });
            })
            .finally(() => {
              this.isLoadingStore = false
            })
          }
        }else{
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Erro de validação!',
              icon: 'AlertTriangleIcon',
              text: 'Por favor preencha todos os campos obrigatorios.',
              variant: 'danger'
            }
          })
        }
      })
    },

    fetchSegments(pagination = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("segments/fetchSegments", {
          pagination: pagination,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
          this.isLoading = false;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },


    initValues(){
      this.dataCompanies.fantasy_name = null,
      this.dataCompanies.segment_id = null,
      this.dataCompanies.whatsapp = null,
      this.exclusive = false,
      this.dataCompanies.address.city = null,
      this.dataCompanies.address.zip_address = null,
      this.dataCompanies.address.street = null,
      this.dataCompanies.address.state = null,
      this.dataCompanies.address.number = null,
      this.dataCompanies.address.complement = null,
      this.dataCompanies.address.neighborhood = null,
      this.logo = null,

      this.$nextTick(() => this.$refs.simpleRules.reset());
    },

    closeSidebar(){
      this.$refs['sidebar-create'].hide()
    },
  },
  created() {
    this.fetchSegments();
  },
  watch: {
    dataProp: {
      immediate: true, 
      handler (val, oldVal) {
        if (!val) return; 
        if (this.dataProp.id == null)  { 
          this.dataCompanies.id = null,
          this.initValues(); 
          
        } else { 

          // let dataModel = this.$store.getters['companies/getCompanieById'](this.dataProp.id)

          this.dataCompanies.id = null,
          this.dataCompanies.id = this.dataProp.id,
          this.dataCompanies.fantasy_name = this.dataProp.fantasy_name,
          this.dataCompanies.segment_id = this.dataProp.segment_id,
          this.dataCompanies.whatsapp = this.dataProp.whatsapp,
          this.exclusive = this.dataProp.exclusive,
          this.dataCompanies.address.city = this.dataProp.address.city,
          this.dataCompanies.address.zip_address = this.dataProp.address.zip_address,
          this.dataCompanies.address.street = this.dataProp.address.street,
          this.dataCompanies.address.state = this.dataProp.address.state,
          this.dataCompanies.address.number = this.dataProp.address.number,
          this.dataCompanies.address.complement = this.dataProp.address.complement,
          this.dataCompanies.address.neighborhood = this.dataProp.address.neighborhood
        } 
      }
    }
  }
}
</script>